<template>
  <div class="va-row">
    <div class="flex md12 xs12">
      <vuestic-widget class="no-padding no-v-padding">
        <vuestic-breadcrumbs
          :breadcrumbs="breadcrumbs"
          :currentRouteName="currentRouteName"
        />
      </vuestic-widget>
    </div>
  </div>
</template>

<script>
import { breadcrumbs } from './Breadcrumbs'

export default {
  name: 'app-breadcrumbs',
  data() {
    return {
      breadcrumbs: breadcrumbs
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    }
  }
}
</script>
<style lang="scss" scoped>
.flex {
  padding: calc(0.5rem + 2px) !important;
}
.vuestic-widget {
  margin-bottom: 30px;
  position: relative;
  &:after {
    content: '';
    display: block;
    border-bottom: 1px solid $gray;
    position: absolute;
    bottom: -25px;
    left: 25px;
    right: 25px;
  }
}
</style>
