<template>
  <div class="d-flex flex-column justify-content-start p-4 call-details">
    <h2 class="m-0">Call Details</h2>

    <div v-if="loading" class="text-center">
      <b-spinner></b-spinner>
    </div>

    <div v-else-if="!loading && !callDetails" class="text-center">
      <p>No call details found</p>
    </div>

    <div v-else>
      <Field label="Call Information">
        <b-textarea
          class="border"
          :value="callDetails.information"
          readonly
          disabled
          rows="10"
        ></b-textarea>
      </Field>
    </div>

    <Field label="Write your notes">
      <b-textarea v-model="notes" class="border"></b-textarea>
    </Field>
    <b-button
      size="sm"
      class="px-3 py-2"
      @click="saveNotes"
      :disabled="addingNote"
    >
      <b-icon icon="save" class="me-2"></b-icon>
      <span v-if="addingNote">Saving...</span>
      <span v-else>Save Notes</span>
    </b-button>
  </div>
</template>

<script>
import webrtcService from '../../services/webrtc.service'
import Field from '../Field.vue'

export default {
  components: {
    Field
  },
  data() {
    return {
      callDetails: {},
      loading: false,
      notes: '',
      addingNote: false
    }
  },
  props: {
    channelId: {
      type: String,
      required: true
    }
  },
  methods: {
    async fetchCallDetails() {
      try {
        this.loading = true
        this.callDetails = await webrtcService.getCallDetails(this.channelId)
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async saveNotes() {
      try {
        this.addingNote = true
        await webrtcService.saveCallNotes(this.channelId, this.notes)
        this.toast('Notes saved successfully')
        this.$emit('notesSaved')
      } catch (error) {
        console.error(error)
        this.toast('Failed to save notes', {
          type: 'error'
        })
      } finally {
        this.addingNote = false
      }
    }
  },
  mounted() {
    this.fetchCallDetails()
  }
}
</script>

<style scoped lang="scss">
.call-details {
  gap: 1rem;
  text-align: start;
}
</style>
