export const breadcrumbs = {
  root: {
    name: '/',
    displayName: 'breadcrumbs.home'
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'sidebar.dashboard'
    },
    {
      name: 'all-customers',
      displayName: 'menu.customers',
      children: [
        {
          name: 'customer-create',
          displayName: 'menu.create'
        }
      ]
    },
    {
      name: 'extensions',
      displayName: 'sidebar.extensions.title',
      disabled: true,
      children: [
        {
          name: 'all-extensions',
          displayName: 'sidebar.extensions.list'
        },
        {
          name: 'monitor-extensions',
          displayName: 'sidebar.extensions.monitor'
        },
        {
          name: 'extension-create',
          displayName: 'create-extension.create'
        },
        {
          name: 'extension-update',
          displayName: 'update-extension.update'
        }
      ]
    },
    {
      name: 'call-reporting',
      displayName: 'call-reporting.call-reporting'
    },
    {
      name: 'call-survey',
      displayName: 'menu.survey.title',

      children: [
        {
          name: 'call-survey',
          displayName: 'menu.survey.call-survey'
        },
        {
          name: 'survey-create',
          displayName: 'menu.survey.survey-create'
        },
        {
          name: 'survey-cdrs-details',
          displayName: 'Details'
        },
        {
          name: 'survey-cdrs-charts',
          displayName: 'Charts'
        }
      ]
    },
    {
      name: 'billing',
      displayName: 'sidebar.billing.title',
      disabled: true,
      children: [
        {
          name: 'billing-rates',
          displayName: 'sidebar.billing.rates'
        },
        {
          name: 'billing-refill',
          displayName: 'sidebar.billing.refill-balance'
        },
        {
          name: 'billing-payments',
          displayName: 'sidebar.billing.payments-history'
        },
        {
          name: 'billing-charges',
          displayName: 'sidebar.billing.charges'
        },
        {
          name: 'billing-invoices',
          displayName: 'sidebar.billing.invoices'
        }
      ]
    },
    //
    {
      name: 'auto-dialer-list',
      displayName: 'Auto Dialer',

      children: [
        {
          name: 'auto-dialer-list',
          displayName: 'Auto Dialer List'
        },
        {
          name: 'auto-dialer-create',
          displayName: 'Settings'
        },
        {
          name: 'auto-dialer-edit',
          displayName: 'Edit Settings'
        },
        {
          name: 'auto-dialer-details',
          displayName: 'Details'
        },
        {
          name: 'auto-dialer-statistics',
          displayName: 'Statistics'
        }
      ]
    },
    {
      name: 'conference-bridge',
      displayName: 'menu.conferenceBridge.title',
      children: [
        {
          name: 'callflow',
          displayName: 'menu.conferenceBridge.callflow'
        },

        {
          name: 'conference-bridge-create',
          displayName: 'menu.conferenceBridge.create_title'
        },
        {
          name: 'conference-bridge-update',
          displayName: 'menu.conferenceBridge.update_title'
        }
      ]
    },
    {
      name: 'voice-messages',
      displayName: 'menu.voiceMessages.title'
    },
    {
      name: 'phonebook-list',
      displayName: 'menu.phonebook.title',
      children: [
        {
          name: 'phonebook-update',
          displayName: 'menu.update'
        }
      ]
    },
    {
      name: 'campaigns-list',
      displayName: 'menu.campaigns.title',
      children: [
        {
          name: 'campaigns-create',
          displayName: 'menu.create'
        }
      ]
    },
    {
      name: 'orders-list',
      displayName: 'menu.orders-confirmation.title',
      children: [
        {
          name: 'orders-create',
          displayName: 'menu.orders-confirmation.create'
        },
        {
          name: 'orders-edit',
          displayName: 'menu.orders-confirmation.edit'
        }
      ]
    },
    {
      name: 'all-developers-tab',
      displayName: 'breadcrumbs.developer-tab.title',
      disabled: true,
      children: [
        {
          name: 'all-webrtc-credentials',
          displayName: 'breadcrumbs.webrtc-credentials.title',
          disabled: true,
          children: [
            {
              name: 'webrtc-credentials',
              displayName: 'breadcrumbs.webrtc-credentials.list'
            },
            {
              name: 'create-webrtc-credentials',
              displayName: 'breadcrumbs.webrtc-credentials.create'
            }
          ]
        }
      ]
    },
    {
      name: 'inno-support',
      displayName: 'sidebar.support',
      disabled: true
    },
    {
      name: 'all-packages',
      displayName: 'menu.packages'
    },
    {
      name: 'all-invoices',
      displayName: 'menu.billing.invoices'
    },
    {
      name: 'usage',
      displayName: 'menu.billing.usage-title',
      disabled: true,
      children: [
        {
          name: 'billing-summary',
          displayName: 'menu.billing.summary'
        },
        {
          name: 'billing-detailed',
          displayName: 'menu.billing.detailed'
        }
      ]
    },
    {
      name: 'settings',
      displayName: 'breadcrumbs.settings',
      disabled: true,
      children: [
        {
          name: 'change-password',
          displayName: 'breadcrumbs.change-password'
        },
        {
          name: 'customer-create-field',
          displayName: 'menu.create_field'
        },
        {
          name: 'crm-integrate',
          displayName: 'crm.integrate_title'
        }
      ]
    }
  ]
}
