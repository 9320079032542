<template>
  <vuestic-sidebar :hidden="!isOpen">
    <template slot="menu">
      <sidebar-link :to="{ name: 'dashboard' }">
        <span slot="title" class="sidebar-menu-item-title">
          <span class="sidebar-menu-item-image">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path fill="none" d="M0 0h24v24H0V0z"></path>
              <path
                d="M4 13h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zm0 8h6c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1zm10 0h6c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zM13 4v4c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1z"
              ></path>
            </svg>
          </span>
          <span>{{ $t('sidebar.dashboard') }}</span>
        </span>
      </sidebar-link>
      <sidebar-link :to="{ name: 'call-reporting' }">
        <span slot="title" class="sidebar-menu-item-title">
          <span class="sidebar-menu-item-image">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path fill="none" d="M0 0h24v24H0V0z"></path>
              <path
                d="M4 13h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zm0 8h6c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1zm10 0h6c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zM13 4v4c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1z"
              ></path>
            </svg>
          </span>
          <span>{{ $t('sidebar.call-reporting') }}</span>
        </span>
      </sidebar-link>
    </template>
  </vuestic-sidebar>
</template>

<script>
import VuesticSidebar from '../../vuestic-theme/vuestic-components/vuestic-sidebar/VuesticSidebar'
import SidebarLink from '../admin/app-sidebar/components/SidebarLink'
import { mapGetters } from 'vuex'
export default {
  name: 'app-sidebar',
  components: {
    VuesticSidebar,
    SidebarLink
  },
  data() {
    return {
      canCreate: false
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(['pbxUrl'])
  }
}
</script>
<style lang="scss">
.sidebar-link {
  a {
    .sidebar-menu-item-title {
      display: flex;
      align-items: center;
      .sidebar-menu-item-image {
        width: 30px;
        margin-right: 10px;
        text-align: center;
        @media (max-width: 1368px) {
          width: 24px;
        }
        svg {
          fill: $blue;
          @media (max-width: 1368px) {
            width: 15.51px;
          }
        }
        i {
          color: $blue;
        }
      }
    }
    &.router-link-exact-active,
    &:hover {
      .sidebar-menu-item-title {
        .sidebar-menu-item-image {
          svg {
            fill: $white;
          }
          i {
            color: $white;
          }
        }
      }
    }
  }
}
</style>
