import AgentLayout from '../../layouts/AgentLayout.vue'

const agentRoutes = [
  {
    path: '/',
    component: AgentLayout,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import(`../../pages/agent/Dashboard.vue`),
        default: true
      },
      {
        path: 'call-reporting',
        name: 'call-reporting',
        component: () => import(`../../pages/agent/CallReporting.vue`)
      }
    ],
    meta: { requiresAuth: true, userType: 'agent' }
  }
]

export default agentRoutes
